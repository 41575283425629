// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: true,
  useQandAs: true,
  useQandASearch: true,
  useRegionPerLocale: false,
  useFeedbackPrompt: false,

  // Configuration of content
  localeLanguage: 'en',
  localeDir: 'ltr',
  localeAlternatives: 'ar:العربية, en:English, es:Español, fr:Français, *:Other...',
  localeAlternativesExplanation: '* Translations via Google Translate',
  appName: 'HIA \n Caribbean Branch Meeting and Academy 2024',
  appLogoUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_the_Red_Cross.svg',
  mainPageHeader: 'Information and FAQ for Caribbean Branch Meeting and Academy 2024',
  mainPageIntroduction: 'This Helpful Information web-App (HIA) is made to brief you for and answer your frequently asked questions about the Caribbean Branch Meeting and Board Academy (7 - 10 November 2024) \n \n Please click on the button below to access all information',
  mainPageNotification: '',
  pageAbout: '\n',
  pagePrivacy: '\n',
  mainFooter: '**Helpful Information**  \n \n',
  errorHeader: 'Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'https://github.com/lotjeredcross/hia-caribbean-bmba',
  errorRetry: 'Try again?',

  // Regions:
  regionConfig: '',
  regions: 'info',
  regionsLabels: 'Information',
  regionsSheetIds: '1hmaUdQ8wDRcz8GUpD-CceZm1eR8bnYrLetf_lNxXMWE',

  // Third-party tokens:
  google_sheets_api_key: 'AIzaSyAa4CqvSDbh2Opzg2rdHfjhESSkm55b9g0',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',
  matomoConnectionString: '',
  appInsightsConnectionString: '',
};
